import { action } from '@ember/object';
import { service } from '@ember/service';
import queryParamsUnpack from '@mvb/tix-ui/utils/query-params-unpack';
import Route from '@ember/routing/route';

export default class CompaniesIndexRoute extends Route {
  @service('search-companies') searchService;
  @service session;

  queryParams = {
    qp: {
      refreshModel: true,
    },
  };

  async beforeModel(transition) {
    await super.beforeModel(...arguments);

    if (!this.session.requireAuthentication(transition)) {
      return;
    }
  }

  @action
  didTransition() {
    this.search();
  }

  resetController(_, isExiting) {
    if (isExiting) {
      this.searchService.reset();
    }
  }

  search() {
    let params = this.paramsFor(this.routeName);
    this.searchService.search(null, { replaceRoute: true }, queryParamsUnpack(params));
  }
}
