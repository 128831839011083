import { PARTY_PERMISSIONS } from '@mvb/tix-ui/constants';
import { service } from '@ember/service';
import Ability from './-base';

export default class extends Ability {
  @service abilities;
  @service features;
  @service user;

  get deleteHugendubelSuggests() {
    return this.user.hasPermission(PARTY_PERMISSIONS.MANAGE_HUG_CMC);
  }

  get viewDefaultCurrencySettings() {
    return this.features.isEnabled('currency-settings');
  }

  get viewDefaultNotesTypeSettings() {
    return this.features.isEnabled('groups') && this.model.user.isCorporateUser;
  }

  get viewAnyUserSettings() {
    return (
      this.viewDefaultCurrencySettings ||
      this.viewDefaultNotesTypeSettings ||
      this.viewHighlightsBookGroupsToggle ||
      this.deleteHugendubelSuggests ||
      this.abilities.can('dispoList.viewDispoListsInProductPages')
    );
  }

  get viewHighlightsBookGroupsToggle() {
    return this.model.showHighlightsBookGroupsToggle;
  }
}
