import { hash } from 'ember-concurrency';
import { service } from '@ember/service';
import { URL_USER_TOGGLE_HIGHLIGHTS_BHG } from '@mvb/tix-ui/constants';
import Route from '@ember/routing/route';

export default class ProfileRoute extends Route {
  @service api;
  @service session;
  @service store;
  @service user;

  async beforeModel(transition) {
    await super.beforeModel(...arguments);

    if (!this.session.requireAuthentication(transition)) {
      return;
    }
  }

  async model() {
    let user = this.store.findRecord('user', this.user.current?.id, {
      include: 'address,avatar,registrationInput,blogInfo',
    });

    return hash({
      showHighlightsBookGroupsToggle: this.api.get(URL_USER_TOGGLE_HIGHLIGHTS_BHG),
      user,
    });
  }
}
