export default function sanitizeString(string) {
  if (!string) {
    return '';
  }

  string = string
    .replaceAll('&nbsp;', ' ')
    .replaceAll('&amp;', '&')
    .replaceAll(/\s*(<br\s*\/?\s*>)+|(<br\s*\/?\s*>)+\s*$/gi, ' ')
    .replaceAll(/\s\s+/g, ' ');
  return string.trim();
}
