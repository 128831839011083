import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("{{! template-lint-disable no-invalid-interactive}}\n<div\n  data-test-tooltip\n  data-tooltip-wrapper\n  local-class={{if @isErrorTooltip \"error-tooltip-wrapper\" \"tooltip-wrapper\"}}\n  ...attributes\n  {{on \"click\" this.onClick passive=true}}\n>\n  {{#if (has-block \"trigger\")}}\n    {{yield to=\"trigger\"}}\n  {{else}}\n    <FaIcon @icon=\"circle-info\" @prefix=\"fal\" />\n  {{/if}}\n\n  <Tippy @placement={{this.side}} @theme={{this.theme}} @trigger={{if @isErrorTooltip \"mouseenter focus\" \"click\"}}>\n    {{#if (has-block \"content\")}}\n      {{yield to=\"content\"}}\n    {{else if (has-block)}}\n      {{yield}}\n    {{else if @text}}\n      {{@text}}\n    {{/if}}\n  </Tippy>\n</div>", {"contents":"{{! template-lint-disable no-invalid-interactive}}\n<div\n  data-test-tooltip\n  data-tooltip-wrapper\n  local-class={{if @isErrorTooltip \"error-tooltip-wrapper\" \"tooltip-wrapper\"}}\n  ...attributes\n  {{on \"click\" this.onClick passive=true}}\n>\n  {{#if (has-block \"trigger\")}}\n    {{yield to=\"trigger\"}}\n  {{else}}\n    <FaIcon @icon=\"circle-info\" @prefix=\"fal\" />\n  {{/if}}\n\n  <Tippy @placement={{this.side}} @theme={{this.theme}} @trigger={{if @isErrorTooltip \"mouseenter focus\" \"click\"}}>\n    {{#if (has-block \"content\")}}\n      {{yield to=\"content\"}}\n    {{else if (has-block)}}\n      {{yield}}\n    {{else if @text}}\n      {{@text}}\n    {{/if}}\n  </Tippy>\n</div>","moduleName":"@mvb/tix-ui/components/ui/tooltip/index.hbs","parseOptions":{"srcName":"@mvb/tix-ui/components/ui/tooltip/index.hbs"}});
import { action } from '@ember/object';
import { DEFAULT_TOOLTIP_SIDE } from '@mvb/tix-ui/constants';
import Component from '@glimmer/component';

export default class UiTooltipComponent extends Component {
  // needed for dynamic repositioning according to the viewport space
  // popperOptions only accepts modifiers, see https://github.com/sir-dunxalot/ember-tooltips#popperoptions
  // for more modifiers options, see https://popper.js.org/
  popperOptions = {
    modifiers: {
      preventOverflow: {
        escapeWithReference: false,
        boundariesElement: 'viewport',
        padding: 10,
      },
      flip: {
        flipVariations: true,
        flipVariationsByContent: true,
        padding: 10,
      },
    },
  };

  get theme() {
    return this.args.isErrorTooltip ? 'danger' : this.args.theme;
  }

  get side() {
    return this.args.side || DEFAULT_TOOLTIP_SIDE; // side options are: right, left, top, bottom
  }

  @action
  onClick(event) {
    event.stopPropagation();
  }
}
