import { attr, belongsTo } from '@ember-data/model';
import { isEmpty, isNone } from '@ember/utils';
import Model from './-base';
import sanitizeString from '@mvb/tix-ui/utils/sanitize-string';

export default class ContributorModel extends Model {
  @attr('string') biographicalNote;
  @attr('string') firstName;
  @attr('string') gnd;
  @attr('string') groupName;
  @attr('string') isni;
  @attr('string') lastName;
  @attr('string') orcId;
  @attr('string') prefixToKey;
  @attr('number') sequenceNumber;
  @attr('number') sequenceWithinRole;
  @attr('string') type;
  @attr() webSites;

  @belongsTo('product', { async: false, inverse: 'contributors' }) product;

  get hasContributorIdentifier() {
    return this.isni || this.orcId || this.gnd;
  }

  get name() {
    let firstName = isNone(this.firstName) ? '' : this.firstName;
    let lastName = isNone(this.lastName) ? '' : this.lastName;
    let prefixToKey = isNone(this.prefixToKey) ? '' : this.prefixToKey;
    let groupName = isNone(this.groupName) ? '' : this.groupName;

    let fullName = `${firstName} ${prefixToKey} ${lastName}`;
    let name = fullName.replaceAll(/\s\s+/g, ' ').trim();

    let contributorName = isEmpty(name) ? groupName : name;
    return sanitizeString(contributorName);
  }

  get webSiteUrls() {
    return this.webSites?.map((site) => site.url);
  }
}
