import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("{{#if this.content}}\n  <div local-class=\"marketing\" data-test-marketing>\n    {{yield}}\n\n    {{#each this.content as |resource|}}\n      <div local-class=\"content\" data-test-marketing-content>\n        <div local-class=\"image\" data-test-marketing-image>\n          <Ui::LazyImg @file={{resource.file}} @alt={{t \"productMarketing.text.alt\" text=resource.caption}} />\n        </div>\n        {{#if resource.copyright}}\n          <p local-class=\"copyright\" data-test-marketing-copyright data-copyright={{resource.copyright}}>\n            {{resource.copyright}}\n          </p>\n        {{/if}}\n        {{#if resource.caption}}\n          <h3 local-class=\"subtitle\" title={{resource.caption}} data-test-marketing-caption>\n            {{resource.caption}}\n          </h3>\n        {{/if}}\n      </div>\n    {{/each}}\n  </div>\n{{/if}}", {"contents":"{{#if this.content}}\n  <div local-class=\"marketing\" data-test-marketing>\n    {{yield}}\n\n    {{#each this.content as |resource|}}\n      <div local-class=\"content\" data-test-marketing-content>\n        <div local-class=\"image\" data-test-marketing-image>\n          <Ui::LazyImg @file={{resource.file}} @alt={{t \"productMarketing.text.alt\" text=resource.caption}} />\n        </div>\n        {{#if resource.copyright}}\n          <p local-class=\"copyright\" data-test-marketing-copyright data-copyright={{resource.copyright}}>\n            {{resource.copyright}}\n          </p>\n        {{/if}}\n        {{#if resource.caption}}\n          <h3 local-class=\"subtitle\" title={{resource.caption}} data-test-marketing-caption>\n            {{resource.caption}}\n          </h3>\n        {{/if}}\n      </div>\n    {{/each}}\n  </div>\n{{/if}}","moduleName":"@mvb/tix-ui/components/product/marketing/index.hbs","parseOptions":{"srcName":"@mvb/tix-ui/components/product/marketing/index.hbs"}});
import { ASSET_TYPE_CODES } from '@mvb/tix-ui/constants';
import Component from '@glimmer/component';

export default class ProductMarketingComponent extends Component {
  get content() {
    return (
      this.args.section?.sortedContents?.filter(
        (resource) => resource.resourceContentType === ASSET_TYPE_CODES.MARKETING
      ) || []
    );
  }
}
