import { service } from '@ember/service';
import Route from '@ember/routing/route';

export default class ProfileUserRoute extends Route {
  @service session;

  model() {
    return this.modelFor('profile').user;
  }
}
