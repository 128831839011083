import { isPresent } from '@ember/utils';
import { PARTY_TYPES_FOR_COMPANY_PAGES, SEARCH_PAGE_SIZES } from '@mvb/tix-ui/constants';
import { service } from '@ember/service';
import SearchBaseService from '@mvb/tix-ui/services/search-base';

export default class SearchCompaniesService extends SearchBaseService {
  @service store;

  get queryParamsDefaults() {
    return {
      page: 1,
      term: '',
      sort: 'name',
    };
  }

  get queryParamsFilters() {
    return ['term'];
  }

  get queryParamsUserSpecific() {
    return {
      size: SEARCH_PAGE_SIZES[0],
    };
  }

  get sortOrders() {
    let sortKeys = ['name', 'favoritePublisher'];
    return [{ value: null }].concat(...sortKeys.map((value) => [{ value }, { value: `-${value}` }]));
  }

  createSearchQuery({ queryParams }) {
    let { page, size, sort } = queryParams;

    let query = {
      filter: {
        type: PARTY_TYPES_FOR_COMPANY_PAGES,
        active: true,
      },
      page: {
        number: page,
        size,
      },
    };

    this.addFiltersToSearchQuery({ query, queryParams });

    if (isPresent(sort)) {
      query.sort = sort;
    }

    return query;
  }

  async executeSearchQuery(query) {
    return await this.store.query('party', query);
  }
}
