import { WEBSHOP_LINK_TYPES_WITH_STORE_ID } from '@mvb/tix-ui/constants';
import applicationUtil from '@mvb/tix-ui/utils/application';
import buildMessage from 'ember-changeset-validations/utils/validation-errors';

export default function urlLink() {
  return (key, newValue, _oldValue, changes, content) => {
    let regexAllowWhitespaceAtBeginAndEndButNotInMiddle = new RegExp(/^\s*[^ ]+\s*$/);
    let matchInvalidCharactersForUrl = new RegExp(/^[^"#%'()<>[\\\]^_`{|}]*$/);
    if (
      !newValue ||
      newValue.length === 0 ||
      (regexAllowWhitespaceAtBeginAndEndButNotInMiddle.test(newValue) && matchInvalidCharactersForUrl.test(newValue))
    ) {
      return true;
    }

    let type = changes.type ?? content.type;
    let messageKey = WEBSHOP_LINK_TYPES_WITH_STORE_ID.includes(type)
      ? 'validation.text.storeId'
      : 'validation.text.webShopName';
    let intlService = applicationUtil.lookup('service:intl');
    return buildMessage(key, {
      message: intlService.t(messageKey),
    });
  };
}
