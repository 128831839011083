export default {
  "table-wrapper-active": "_table-wrapper-active_9y2m3l",
  "table-wrapper-deactive": "_table-wrapper-deactive_9y2m3l",
  "modal-wrapper": "_modal-wrapper_9y2m3l",
  "table": "_table_9y2m3l",
  "search": "_search_9y2m3l",
  "filter-form": "_filter-form_9y2m3l",
  "group": "_group_9y2m3l",
  "centered": "_centered_9y2m3l",
  "icon-wrapper": "_icon-wrapper_9y2m3l",
  "select-all": "_select-all_9y2m3l",
  "tooltip": "_tooltip_9y2m3l",
  "checkbox": "_checkbox_9y2m3l",
  "tooltipp-icon": "_tooltipp-icon_9y2m3l",
  "input-search": "_input-search_9y2m3l",
  "no-lists-wrapper": "_no-lists-wrapper_9y2m3l"
};
