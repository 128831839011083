import { service } from '@ember/service';
import Route from '@ember/routing/route';

export default class ProfileSettingsRoute extends Route {
  @service abilities;
  @service router;

  beforeModel() {
    let profileModel = this.modelFor('profile');

    if (!this.abilities.can('userSettings.viewAnyUserSettings', profileModel)) {
      return this.router.replaceWith('profile.user');
    }
  }

  model() {
    return this.modelFor('profile');
  }
}
