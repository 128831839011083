import { productFruits } from 'product-fruits';
import Service from '@ember/service';

export default class ProductFruitsService extends Service {
  initProductFruits(userId, type) {
    productFruits.init(
      'Dp3DAlje5gUS2ERW',
      'de',
      { username: userId, role: type },
      { disableLocationChangeDetection: false }
    );
  }
}
