import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<p local-class=\"contributors-wrapper\" data-test-list-view-bibliography-contributors ...attributes>\n  {{this.contributors}}\n</p>", {"contents":"<p local-class=\"contributors-wrapper\" data-test-list-view-bibliography-contributors ...attributes>\n  {{this.contributors}}\n</p>","moduleName":"@mvb/tix-ui/components/list-view/bibliography/contributors.hbs","parseOptions":{"srcName":"@mvb/tix-ui/components/list-view/bibliography/contributors.hbs"}});
import Component from '@glimmer/component';
import sanitizeString from '@mvb/tix-ui/utils/sanitize-string';

export default class ListViewBibliographyContributorsComponent extends Component {
  get contributors() {
    return sanitizeString(this.args.contributorsRepresentation);
  }
}
